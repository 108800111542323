const toFixedDec = (num: number | undefined): string => {
  if (num) {
    return `$${num.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`
  }
  return ''
}

export { toFixedDec }
