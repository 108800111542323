import * as React from 'react'
import css from './Layout.module.scss'

export interface IProps {
  children: any
  fluid?: boolean
  relative?: boolean
}

/**
 * Wrapper Class for keeping the use of Bootrap components consistent
 */
export default class Container extends React.Component<IProps, any> {
  public render() {
    const { fluid, relative } = this.props
    const container = fluid === undefined ? css['container-fluid'] : fluid ? css['container-fluid'] : css.container
    const isRelative = relative === undefined ? false : relative
    return <div className={`${container} ${isRelative ? css.relative : ''} merce-container`}>{this.props.children}</div>
  }
}
