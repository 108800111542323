import * as React from 'react'
import css from './Modal.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ModalsProps {
  children: any
  onClose: (evt: any) => void
  show?: boolean
  title?: string
  width?: string
  height?: string
  dynamicClip?: boolean
  renderFixedContent?: any
  full?: boolean
  lock?: boolean
  isCloseTextBold?: boolean
  isTitleBackgroundGrey?: boolean
  isOverFlowing?: boolean
}

export default class Modals extends React.Component<ModalsProps, any> {
  divElement: any = null
  constructor(props: ModalsProps) {
    super(props)
    this.state = {
      height: 0,
      orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape',
      isMobile: window.matchMedia('(max-width: 767.98px)').matches,
    }
  }

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      if (!this.props.lock) {
        this.closeModalHook(event)
      }
    }
  }
  setScreenOrientation = () => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      this.setState({ orientation: 'portrait' })
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      this.setState({ orientation: 'landscape' })
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
    window.removeEventListener('resize', this.setScreenOrientation)
    window.matchMedia('(max-width: 767.98px)').removeEventListener('change', e => {
      this.setState({ isMobile: e.matches })
    })
  }
  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
    const height = this.divElement ? this.divElement.clientHeight : 0
    this.setState({ height })

    window.addEventListener('resize', this.setScreenOrientation)
    window.matchMedia('(max-width: 767.98px)').addEventListener('change', e => {
      this.setState({ isMobile: e.matches })
    })
  }
  closeModalHook = (e: React.MouseEvent<any>) => {
    if (this.props.onClose && !this.props.lock) {
      document.body.style.overflow = 'auto'
      this.props.onClose(e)
    }
  }
  getHeight = () => {
    const { height } = this.props
    const targetHeight = height !== undefined ? height : 'auto'
    const style: any = { height: targetHeight }
    if (height === undefined) {
      style.maxHeight = '800px'
    }
    return style
  }
  getBodyStyles = () => {
    const { full, width, isOverFlowing } = this.props
    const targetWidth: string = width !== undefined ? width : '80%'
    const isFull: boolean = full !== undefined ? full : false
    const style: any = {}
    style.width = targetWidth
    if (isFull) {
      style.top = '0'
      style.left = '0'
      style.transform = 'none'
      style.width = '98.5%'
      style.margin = '15px'
    }
    if (isOverFlowing) {
      if (this.state.orientation === 'landscape' && this.state.isMobile) {
        style.top = '0'
      }
      style.maxHeight = '91vh'
      style.overflowY = 'auto'
      style.marginTop = '15px'
      style.marginBottom = '15px'
    }
    return style
  }
  getInnerContentStyles = () => {
    const { dynamicClip, full } = this.props
    const setupDynamicClip: boolean = dynamicClip !== undefined ? dynamicClip : false
    const isFull: boolean = full !== undefined ? full : false
    if (!setupDynamicClip) {
      const style: any = {
        overflow: 'auto',
        ...this.getHeight(),
      }
      if (isFull) {
        style.maxHeight = '91vh'
        style.height = '91vh'
      }
      return style
    }
    const { height } = this.state
    if (height >= 799) {
      return {
        overflow: 'auto',
        maxHeight: '800px',
      }
    }
    return {}
  }
  public render() {
    const { show, title, isCloseTextBold = false, isTitleBackgroundGrey = true } = this.props
    const doShow = show !== undefined ? show : true
    if (!doShow) {
      return <div />
    }
    // document.body.style.overflow = 'hidden'
    return (
      <React.Fragment>
        <div onClick={this.closeModalHook} className={`${css.modalBackground}`} />
        <div className={css.modalBody} style={this.getBodyStyles()}>
          <div className={css.innerBody}>
            <div className={css.titleContainer} style={!isTitleBackgroundGrey ? { backgroundColor: '#fff' } : {}}>
              {!this.props.lock && (
                <div
                  className={css.close}
                  onClick={this.closeModalHook}
                  style={isCloseTextBold ? { fontWeight: 'bold' } : {}}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
              <h1 className={title ? css.title : css.invisible}>{title || ''}</h1>
            </div>
            {this.props.renderFixedContent && this.props.renderFixedContent()}
            <div
              style={this.getInnerContentStyles()}
              className={`${css.modalContent} fadeIn`}
              ref={divElement => (this.divElement = divElement)}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
